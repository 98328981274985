import api from '@/utils/api'

export function gameGiftpackSimple(params) {
    return api.get('wx/v1/gameGiftpacks/simple', { params: params })
}

export function gameGiftpackList(params) {
    return api.get('wx/v1/gameGiftpacks', { params:params })
}

export function login(params) {
    let url = 'wx/v1/login'
    return api.post(url, params)
}

export function pick(params) {
    return api.post('wx/v1/gameGiftpacks/pick/' + params.id)
}
export function minPick(params) {
    return api.post('wx/v1/gameGiftpacks/minPick/' + params.id, params )
}


export function showCdkey(params) {
    return api.get('wx/v1/userGameGiftpacks/' + params.id)
}

export function gameGiftPackDaJinSimple(params) {

    return api.get('wx/v1/gameGiftpacks/dajin/simple', { params: params })
}
export function gameGiftpackDaJinList(params) {
    return api.get('wx/v1/gameGiftpackDaJin', { params: params })
}
export function giftpacks(params) {
    return api.get('wx/v1/giftpacks/nuhuo', { params: params })
}
export function gameGiftpacksWithGid(params) {
    return api.get('wx/v1/gameGiftpacksWithGid', { params: params })
}
export function miniGiftpacksByGid(params) {
    return api.get('wx/v1/getMiniGameGiftByGameId', { params: params })
}
export function loginMiniGame(params) {
    return api.get('wx/v1/giftpacks/login', { params: params })
}
export function getFhlcOfOpenid(params) {
    return api.get('wx/v1/getFhlcOfOpenid', { params: params })
}


//  =================================西游小程序礼包Start========================
export function gameGiftPackDarkXiyouAppletSimple(params) {
    return api.get('wx/v1/gameGiftpacks/dajin/simple', { params: params })
}
export function gameGiftpackDarkXiyouAppletList(params) {
    return api.get('wx/v1/gameGiftpackDaJin', { params: params })
}
export function dartXiyouPick(gift_pack_id) {
    return api.post('wx/v1/gameGiftpacks/receive', { gift_pack_id: gift_pack_id })
}
//  =================================西游小程序礼包End==========================
